.logo {
  height: 65px;
  box-sizing: border-box;
  padding: 20px 15px;
  margin-bottom: 16px;
  text-align: center;
  :global {
    h1 {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.side {
  :global {
    .ant-menu {
      font-size: 16px;
    }
  }
}
.themeLight {
  :global {
    h1 {
      color: #333333;
    }
  }
}
.themeDark {
  :global {
    h1 {
      color: #fff;
    }
  }
}
