@import "./_variables.less";
@import "./_utils.less";
@import "~@ksyun/antd-ksyun-theme/override.less";


.hide {
  display: none;
}

.full-skeleton {
  .@{ant-prefix}-skeleton-element {
    width: 100%;
  }
}
