.menuSkeleton {
  box-sizing: border-box;
  padding: 0 24px;
  :global {
    .ksyun-skeleton {
      margin-bottom: 16px;
    }
    .ksyun-skeleton-element {
      width: 100%;
    }
    .ksyun-skeleton-button {
      width: 60%;
    }
    .ksyun-skeleton-button-lg {
      width: 100%;
    }
  }
}
