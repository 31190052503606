.margin-card {
    &.__mb {
        margin-bottom: @card-margin !important;
    }
    &.__mt {
        margin-top: @card-margin !important;
    }
}

.mb-16 {
    margin-bottom: @card-margin;
}
.mt-16 {
    margin-top: @card-margin;
}
.ml-16 {
    margin-left: @card-margin;
}
.mr-16 {
    margin-right: @card-margin;
}