.logo {
  box-sizing: border-box;
  padding: 0 15px;
  text-align: center;
  float: left;
  :global {
    h1 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}
.menus {
  float: left;
}

.userInfo {
  float: right;
}
.themeLight {
  :global {
    h1 {
      color: #333333;
    }
  }
}
.themeDark {
  :global {
    h1 {
      color: #fff;
    }
  }
}
