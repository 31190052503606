.menuSkeleton {
  display: flex;
  float: left;
  align-items: center;
  width: 300px;
  height: 64px;
  :global {
    .ksyun-skeleton {
      margin-right: 16px;
      line-height: 32px;
    }
    .ksyun-skeleton-button {
      width: 100px;
    }
  }
}